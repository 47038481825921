import React, { useState } from "react";
import Modal from "react-modal";
import NewsletterForm from './NewsletterForm';
import Logo from "../assets/newsletter/digital_leverage_letter.png"

import firstEdition from "../assets/newsletter/newsletter_thumbnails/firstEdition.png"

Modal.setAppElement("#root");

const News = () => {
  const [isOpen, setIsOpen] = useState(false);
  /* copy over for each letter (isOpen2) */

  function toggleModalOne() {
    setIsOpen(!isOpen);
  }
  /* copy over for each letter (toggleModalTwo) */

  return (
    <>
      <div className="container">
        <div className="tokyo_tm_news">
          <div className="tokyo_tm_title">
            <div className="title_flex">
              <div className="left">
                <span>The Digital Leverage Letter</span>
                <h3>My insights on online business, the power of code and media, and skills to leverage the potential of the internet.</h3>
                <img src={Logo} alt="logo" style={{paddingTop: "50px"}}/>
              </div>
            </div>
          </div>

          <NewsletterForm />
          <br/>

          <ul>

            {/* SINGLE ENTRY */}
            <li data-aos="fade-right" data-aos-duration="1200">
              <div className="list_inner">
                <div onClick={toggleModalOne}>
                  <img src={firstEdition} alt="thumbnail" /> {/* THUMBNAIL */}
                </div>

                <div className="details">
                  <div className="extra">
                    <p className="date">Coming Soon...</p>
                  </div>

                  <h3 className="title" onClick={toggleModalOne}>
                    The New Opportunity
                  </h3>
                  <div className="tokyo_tm_read_more">
                    <a href="/" onClick={(e) => {
                      e.preventDefault();
                      toggleModalOne();
                    }}>
                      <span>click to read</span>
                    </a>
                  </div>
                </div>

                <Modal
                  isOpen={isOpen}
                  onRequestClose={toggleModalOne}
                  contentLabel="My dialog"
                  className="mymodal"
                  overlayClassName="myoverlay"
                  closeTimeoutMS={500}>

                  <div className="tokyo_tm_modalbox_news">
                    <button className="close-modal" onClick={toggleModalOne}>
                      <img src={"assets/img/svg/cancel.svg"} alt="close icon" />
                    </button>
                    
                    <div className="box_inner">
                      <div className="description_wrap scrollable">
                        <div className="details">
                          <div className="extra">
                            <p className="date">By Dan Gomes <span/> TBD</p>
                          </div>
                          <h3 className="title">The New Opportunity</h3>
                        </div>

                        <div className="main_content ">
                          <div className="descriptions">
                            <p>
                              Coming soon.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
              </div>
            </li>

            {/* INSERT NEXT ENTRY HERE */}

          </ul>
        </div>
      </div>
    </>
  );
};

export default News;
