import React from "react";
import FunFact from "./FunFact";
import Services from "./Services";

const ServiceMain = () => {
  return (
    <>
      {/* SERVICE */}

      <div className="container">
        <div className="tokyo_tm_services">
          <div className="tokyo_tm_title">
            <div className="title_flex">
              <div className="left">
                <span>Aurelis</span>
                <h3>A Web & Software Development Agency.</h3>
              </div>
            </div>
            <a href="https://aurelisagency.com/" target="_blank" rel="noreferrer">Check out our website here.</a>
          </div>
          {/* End tokyo_tm_title */}

          <div className="list">
            <div className="tokyo_section_title">
              <h3>What We Do</h3>
            </div>
            <ul>
              <Services />
            </ul>
          </div>

          <div className="tokyo_tm_facts">
              <div className="tokyo_section_title">
                <h3>Some Stats</h3>
              </div>
              <div className="list">
                <ul>
                  <FunFact />
                </ul>
              </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceMain;
