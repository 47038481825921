import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useLocation, useNavigate } from "react-router-dom";

import Home from "../../components/Home";
import About from "../../components/about/AboutMain";
import Portfolio from "../../components/PortfolioCreative";
import News from "../../components/News";
import ServiceMain from "../../components/service/ServiceMain";
import Contact from "../../components/Contact";
import PageTitle from "../../components/PageTitle";

const HomeLight = () => {
  const [isDarkMode] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    document.body.classList.toggle("dark", isDarkMode);
    localStorage.setItem("isDarkModeEnabled", isDarkMode);
  }, [isDarkMode, location.pathname]);

  useEffect(() => {
    const paths = ["", "about", "agency", "newsletter", "dev", "contact"];
    const pathIndex = paths.indexOf(location.pathname.split("/")[1]);
    setTabIndex(pathIndex === -1 ? 0 : pathIndex);
  }, [location.pathname]);

  const handleTabSelect = (index) => {
    const paths = ["", "about", "agency", "newsletter", "dev", "contact"];
    navigate(`/${paths[index]}`);
    setTabIndex(index);
  };

  return (
    <>
      <PageTitle title="Daniel Gomes" />

      <Tabs selectedIndex={tabIndex} onSelect={handleTabSelect}>
        <TabList>
          {/* START LEFT MENU CONTENT */}
          <div className="leftpart">
            <div className="leftpart_inner">
              <div className="logo">
                <h3>DG.</h3>
              </div>
              {/* END LOGO */}

              <div className="menu">
                <ul>
                  <Tab>
                    <img
                      className="svg"
                      src="/assets/img/svg/home.svg"
                      alt="homerun"
                    />
                    <span className="menu_content">home</span>
                  </Tab>
                  <Tab>
                    <img
                      className="svg"
                      src="/assets/img/svg/avatar.svg"
                      alt="avatar"
                    />
                    <span className="menu_content">about</span>
                  </Tab>
                  <Tab>
                    <img
                      className="svg"
                      src="/assets/img/svg/planet.svg"
                      alt="avatar"
                    />
                    <span className="menu_content">agency</span>
                  </Tab>
                  <Tab>
                    <img
                      className="svg"
                      src="/assets/img/svg/newsletter.svg"
                      alt="paper"
                    />
                    <span className="menu_content">newsletter</span>
                  </Tab>
                  <Tab>
                    <img
                      className="svg"
                      src="/assets/img/svg/code.svg"
                      alt="briefcase"
                    />
                    <span className="menu_content">dev</span>
                  </Tab>
                  <Tab>
                    <img
                      className="svg"
                      src="/assets/img/svg/contact.svg"
                      alt="mail"
                    />
                    <span className="menu_content">contact</span>
                  </Tab>
                </ul>
              </div>
              {/* END MENU */}

              
            </div>
          </div>
          {/* END LEFT MENU CONTENT */}
        </TabList>
        {/* END SIDEBAR TABLIST */}

        {/* START RIGHT PART CONTENT */}
        <div className="rightpart">
          <div className="rightpart_in">
            <div className="tokyo_tm_section">
              <TabPanel>
                <div data-aos="fade-right" data-aos-duration="1200">
                  <Home />
                </div>
              </TabPanel>
              {/* END HOME MENU TAB CONTENT */}

              <TabPanel>
                <div
                  data-aos="fade-right"
                  data-aos-duration="1200"
                  data-aos-delay="100"
                >
                  <About />
                </div>
              </TabPanel>
              {/* END ABOUT MENU TAB CONTENT */}

              <TabPanel>
                <div
                  data-aos="fade-right"
                  data-aos-duration="1200"
                  data-aos-delay="100"
                >
                  <ServiceMain />
                </div>
              </TabPanel>
              {/* END ABOUT MENU TAB CONTENT */}

              <TabPanel>
              <div
                    data-aos="fade-right"
                    data-aos-duration="1200"
                    data-aos-delay="200"
                  >
                <News />
                </div>
              </TabPanel>
              {/* END PORTFOLIO MENU TAB CONTENT */}

              <TabPanel>
                <div
                    data-aos="fade-right"
                    data-aos-duration="1200"
                    data-aos-delay="200"
                  >
                  <Portfolio />
                  </div>
              </TabPanel>
              {/* END NEWS MENU TAB CONTENT */}

              <TabPanel>
                <div
                  data-aos="fade-right"
                  data-aos-duration="1200"
                  data-aos-delay="200"
                >
                  <Contact />
                </div>
              </TabPanel>
              {/* END CONTACT MENU TAB CONTENT */}
            </div>
          </div>
        </div>
        {/* END RIGHT PART CONTENT */}
      </Tabs>
      {/* END TABS */}
    </>
  );
};

export default HomeLight;
