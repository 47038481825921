import React from "react";
import { Gallery } from "react-photoswipe-gallery";
import KnowledgeInterest from "./about/KnowledgeInterest";

const Portfolio = () => {
  return (
    <>
      <Gallery>
        <div className="container">
          <div className="tokyo_tm_portfolio">
            <div className="tokyo_tm_title">
              <div className="title_flex">
                <div className="left">
                  <span>Developer Portfolio</span>
                  <h3>Some of my past 5+ years of experience.</h3>
                </div>
              </div>
            </div>
            {/* END TOKYO_TM_TITLE */}

            <div className="tokyo_tm_skillbox">
            <div className="tokyo_tm_title">
            <h3>Technical Skills</h3>
            </div>
            <br/>
              <div className="container">
                <div className="in">
                  <KnowledgeInterest />
                </div>
              </div>

                <div className="tokyo_tm_title">
                  <h3>Personal Projects</h3>
                </div>
                </div>

                <div className="container">
                <div className="in">
                <ul data-aos="fade-right" data-aos-duration="1200">
                  <div className="inner">
                    <div className="entry tokyo_tm_portfolio_animation_wrap">
                    <a href="https://github.com/dan1el5/jammy" target="_blank" rel="noreferrer" class="no_underline">
                    <h3>Jammy Bot</h3>
                    <p>A discord bot that plays music.</p>
                    </a>
                    </div>
                  </div>
                </ul><br/>

                <ul data-aos="fade-right" data-aos-duration="1200">
                        <div className="inner">
                          <div className="entry tokyo_tm_portfolio_animation_wrap">
                            <a href="https://github.com/dan1el5/BuildingBuddy" target="_blank" rel="noreferrer" class="no_underline">
                            <h3>Building Buddy</h3>
                            <p>2nd year group project that makes school maps interactive.</p>
                            </a>
                          </div>
                        </div>
                      </ul><br/>

                      <ul data-aos="fade-right" data-aos-duration="1200">
                        <div className="inner">
                          <div className="entry tokyo_tm_portfolio_animation_wrap">
                            <a href="https://github.com/Jaxz102/dt-wdl" target="_blank" rel="noreferrer" class="no_underline">
                            <h3>Western Developer's League</h3>
                            <p>My project during my time at Western Developer's Society.</p>
                            </a>
                          </div>
                        </div>
                      </ul><br/>

                      <ul data-aos="fade-right" data-aos-duration="1200">
                        <div className="inner">
                          <div className="entry tokyo_tm_portfolio_animation_wrap">
                            <a href="https://github.com/dan1el5/WorkoutTracker" target="_blank" rel="noreferrer" class="no_underline">
                            <h3>Workout Tracker</h3>
                            <p>Practice with the MERN stack.</p>
                            </a>
                          </div>
                        </div>
                      </ul><br/>
                      </div>
                      </div>
              </div>
            </div>
      </Gallery>
    </>
  );
};

export default Portfolio;
