import React from "react";

const Intro = () => {
  const introContent = {
    name: "My journey so far.",
    text: (
      <>
        <p>
          Currently in the 4th year of my computer science degree, learning how to build software.
        </p>
        <p>
          Started a web development agency with two of my close friends, building software products for clients.
        </p>
        <p>
          I also write content under my own name on LinkedIn <i>(and in my newsletter)</i> to share my ideas.
        </p>
      </>
    ),
  };

  return (
    <>
      <div className="top_author_image">
      </div>
      <div className="about_title">
        <h3>{introContent.name}</h3>
      </div>
      <div className="about_text">{introContent.text}</div>
    </>
  );
};

export default Intro;
