import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';

const Animation = () => {
  const mountRef = useRef(null);
  const isMouseDownRef = useRef(false);
  const previousMousePosition = useRef({ x: 0, y: 0 });

  useEffect(() => {
    const currentMount = mountRef.current;
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, 1, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });

    const width = 800;
    const height = 800;

    renderer.setSize(width, height);
    renderer.setClearColor(0x000000, 0);
    currentMount.appendChild(renderer.domElement);

    const geometry = new THREE.SphereGeometry(1, 32, 32);
    const material = new THREE.MeshBasicMaterial({ color: 0xffffff, wireframe: true });
    const sphere = new THREE.Mesh(geometry, material);
    scene.add(sphere);

    camera.position.z = 5;

    const animate = function () {
      requestAnimationFrame(animate);

      if (isMouseDownRef.current) {
        const deltaMove = {
          x: previousMousePosition.current.x - isMouseDownRef.current.x,
          y: previousMousePosition.current.y - isMouseDownRef.current.y,
        };

        sphere.rotation.y += deltaMove.x * 0.005;
        sphere.rotation.x += deltaMove.y * 0.005;

        previousMousePosition.current = { ...isMouseDownRef.current };
      } else {
        sphere.rotation.x += 0.005; // Spin faster when not clicked
        sphere.rotation.y += 0.005; // Spin faster when not clicked
      }

      renderer.render(scene, camera);
    };

    animate();

    const onDocumentMouseDown = (event) => {
      isMouseDownRef.current = { x: event.clientX, y: event.clientY };
      previousMousePosition.current = { x: event.clientX, y: event.clientY };
    };

    const onDocumentMouseUp = () => {
      isMouseDownRef.current = false;
    };

    const onDocumentMouseMove = (event) => {
      if (isMouseDownRef.current) {
        isMouseDownRef.current = { x: event.clientX, y: event.clientY };
      }
    };

    const onDocumentTouchStart = (event) => {
      if (event.touches.length === 1) {
        isMouseDownRef.current = { x: event.touches[0].clientX, y: event.touches[0].clientY };
        previousMousePosition.current = { x: event.touches[0].clientX, y: event.touches[0].clientY };
      }
    };

    const onDocumentTouchEnd = () => {
      isMouseDownRef.current = false;
    };

    const onDocumentTouchMove = (event) => {
      if (isMouseDownRef.current && event.touches.length === 1) {
        isMouseDownRef.current = { x: event.touches[0].clientX, y: event.touches[0].clientY };
      }
    };

    currentMount.addEventListener('mousedown', onDocumentMouseDown);
    currentMount.addEventListener('mouseup', onDocumentMouseUp);
    currentMount.addEventListener('mousemove', onDocumentMouseMove);

    currentMount.addEventListener('touchstart', onDocumentTouchStart);
    currentMount.addEventListener('touchend', onDocumentTouchEnd);
    currentMount.addEventListener('touchmove', onDocumentTouchMove);

    return () => {
      currentMount.removeChild(renderer.domElement);
      currentMount.removeEventListener('mousedown', onDocumentMouseDown);
      currentMount.removeEventListener('mouseup', onDocumentMouseUp);
      currentMount.removeEventListener('mousemove', onDocumentMouseMove);

      currentMount.removeEventListener('touchstart', onDocumentTouchStart);
      currentMount.removeEventListener('touchend', onDocumentTouchEnd);
      currentMount.removeEventListener('touchmove', onDocumentTouchMove);
    };
  }, []);

  return <div ref={mountRef} style={{ width: '800px', height: '800px' }} />;
};

export default Animation;







