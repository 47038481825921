import React from "react";
import Modal from "react-modal";
import Intro from "./Intro";
import Resume from "./Resume";

Modal.setAppElement("#root");

const AboutMain = () => {
  return (
    <>
      <div className="container">
        <div className="tokyo_tm_about">
          <div className="tokyo_tm_title">
            <div className="title_flex">
              <div className="left">
                <span>About Me</span>
              </div>
            </div>
          </div>
          {/* End title */}

          <Intro />

          <div className="tokyo_tm_resumebox" style={{backgroundColor: '#111'}}>
            <div className="container">
              <div className="in">
                <Resume />
              </div>
            </div>
          </div>

        </div>
      </div>
      {/* End .container */}

      {/* /ABOUT */}
    </>
  );
};

export default AboutMain;
