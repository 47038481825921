import React from "react";
import HomeLight from "../views/all-home-version/HomeLight";
import NotFound from "../views/NotFound";
import { Routes, Route } from "react-router-dom";

import About from "../components/about/AboutMain";
import Portfolio from "../components/PortfolioCreative";
import News from "../components/News";
import ServiceMain from "../components/service/ServiceMain";
import Contact from "../components/Contact";

const AllRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<HomeLight />}>
          <Route path="about" element={<About />} />
          <Route path="agency" element={<ServiceMain />} />
          <Route path="newsletter" element={<News />} />
          <Route path="dev" element={<Portfolio />} />
          <Route path="contact" element={<Contact />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default AllRoutes;
