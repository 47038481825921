import React from "react";
import Calendly from "./Calendly"

const Contact = () => {

  return (
    <>
      <div className="container">
        <div className="tokyo_tm_contact">
          <div className="tokyo_tm_title">
            <div className="title_flex">
              <div className="left">
                <span>Get In Touch</span>
                <h3>Coffee chat? Let's do it.</h3>
              </div>
            </div>
            <br/>
            <Calendly />
          </div>
          {/* END TITLE */}
        </div>
      </div>
      {/* END COTACT */}
    </>
  );
};

export default Contact;
