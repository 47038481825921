import React from "react";

const Services = () => {

  const serviceContent = [
    {
      id: 1,
      no: "01",
      title: "Web Development",
      text: `Stand out in the digital landscape. We've built some beautiful websites for our clients.`,
    },
    {
      id: 2,
      no: "02",
      title: "Software Development",
      text: `We're no strangers to complex software projects. We know how to map out and build an effective application.`,
    },
    {
      id: 3,
      no: "03",
      title: "Web & Software Maintenance",
      text: `People don't like maintaining software. Why not offer help?`,
    }
  ];

  return (
    <>
      {serviceContent.map((item) => (
        <li key={item.id}>
          <div className="list_inner">
            <span className="number">{item.no}</span>
            <h3 className="title">{item.title}</h3>
            <p className="text">{item.text}</p>
          </div>
        </li>
      ))}
    </>
  );
};

export default Services;
