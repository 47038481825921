import React, {Component} from "react";

class Calendly extends Component {
    componentDidMount() {
      const head = document.querySelector('head');
      const script = document.createElement('script');
      script.setAttribute('src','https://assets.calendly.com/assets/external/widget.js');
      head.appendChild(script);
    }
    
    render(){
      return (
        <div>
          <div style={{ position: 'relative', overflow: 'hidden'}}>
            <div 
              className="calendly-inline-widget"
              data-url="https://calendly.com/dangomes-chat/30min"
              style={{ minWidth: '320px', height: '1000px', overflow: 'none' }} />
          </div>
        </div>
      );
    }
  }

export default Calendly;